@import '~assets/styles/variables';

.header {
  min-height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing--medium;
  margin-bottom: 2em;
}
