@import '~assets/styles/variables';

.wrapper {
  height: 100%;
  position: relative;
}

.panel {
  height: 100%;
  overflow: auto;
}

.container {
  position: relative;
  min-height: 100%;
}

.loadbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
}

.input {
  display: none !important;
}

.upload {
  position: absolute;
}

.drop {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  display: block;
  border-radius: 5px;
  border: 2px solid $color--accent;
  background-color: rgba($color--accent, 0.25);
  z-index: 3;
  pointer-events: none;
}
