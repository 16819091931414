@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.header {
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 25;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $color--white;
  border-bottom: $rule--full;
  padding: 0 1rem;
  .logo {
    flex: 1 100%;
    margin: 1em auto;
    text-align: center;
    img {
      height: 35px;
      width: auto;
    }
    @include layout--small {
      order: 0;
      img {
        height: 46px;
      }
    }
  }
  @include layout--small {
    flex-flow: row wrap;
    height: auto;
    padding: 1em 0;
  }
}

.menu {
  &__button {
    color: $color--primary--dark;
    box-shadow: none !important;
    border-color: transparent !important;
    background-color: transparent !important;
    &__icon {
      color: $color--white;
      &__container {
        display: flex;
        background-color: $color--accent--light;
        border-radius: 50%;
        margin-right: 0.5rem;
        padding: 0.4rem;
      }
    }
  }
  @include layout--small {
    order: 2;
  }
}

.icon-container {
  background: #48b0f7;
  color: white;
  height: 2em;
  width: 2em;
  padding: 0.25em;
  border-radius: 2em;
  margin-left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.action-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include layout--small {
    order: 3;
  }
}
