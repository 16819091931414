@import '~assets/styles/variables';

.container {
  background-color: $color--background--dark;
  overflow: hidden;
  border-bottom: $rule--full;
  z-index: 3;
  position: relative;
  padding-right: 3px;
}

.sidebar {
  overflow: scroll;
  padding: 1rem 1rem 1rem 0.875rem;
  width: 350px;
  height: 100%;
}

.bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: ew-resize;
  width: 10px;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background-color: #d5d9db;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    &::after {
      background-color: #c0c2c4;
    }
  }

  &--dragging,
  &--dragging:hover {
    &::after {
      background-color: $color--accent;
    }
  }
}
