@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.side-navigation {
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  color: #fafafa;
  width: 26vw;
  @include layout--medium {
    width: 40vw;
  }
  @include layout--medium {
    flex-direction: row wrap;
    width: auto;
  }
  &__item {
    display: inline-block;
    border-right: 8px solid transparent;
    color: $color--primary--lightest;
    text-transform: uppercase;
    padding: 2rem;
    &:hover {
      background: $color--accent--lightest;
      color: $color--accent;
      border-right: $rule;
    }
    &:focus {
      color: $color--accent;
    }
    &--active {
      background: $color--accent--lightest;
      border-color: $color--accent--light;
      color: black;
    }
  }
}
