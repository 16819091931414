@import '~assets/styles/variables';

.shadow {
  position: sticky;
  top: 60px;
  z-index: 3;
  @include transition-out(box-shadow);
  border-radius: $border-radius--medium;

  &.shadow--stuck {
    box-shadow: $shadow--medium;

    .container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .banner {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .container {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius--small;
  }

  .banner {
    background-color: $color--accent;
    border: 0;
    overflow: hidden;
    &--warn {
      background-color: $color--negative;
    }
  }

  .loadbar {
    background-color: rgba($color--white, 0.2);
    position: absolute;
    top: 0;

    > div {
      background-color: rgba($color--white, 0.4);
    }
  }
}

.content {
  display: flex;
  align-items: center;

  p {
    color: $color--accent--lightest;
    padding: $spacing--medium;
  }

  .button {
    background-color: $color--white;
  }
}
