@import '~assets/styles/variables';

.header {
  flex: 0;
  border-bottom: $rule--full;
  background-color: $color--white;
}

.title {
  padding: 1rem;
}
