@import '~assets/styles/variables';

.container {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &--dragging {
    padding-bottom: 1.5rem;

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0.5rem;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      border: 1px dashed $color--accent;
      background-color: rgba($color--accent, 0.25);
      border-radius: 2px;
      height: 1rem;
    }

    .item,
    > * {
      display: none;
    }
  }

  &--root {
    padding: 0;
  }
}

.preview {
  position: absolute;
  pointer-events: none;
  top: 0.5rem;
  bottom: 0.5rem;
  left: 0;
  right: 0;
}

.item {
  position: relative;
  background-color: $color--white;
  border: $rule--full;
  border-radius: $border-radius--small;
  box-shadow: $box-shadow--card;
  display: flex;
  align-items: center;
  padding-left: 4px;
  &:focus,
  &:focus-within {
    border-color: $color--accent--light;
    box-shadow: $shadow--medium;
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
    flex: 1;
    color: $palette--text;
    line-height: 24px;
    &__label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &__archived {
      color: $palette--text--disabled;
      display: inline-flex;
      align-items: center;
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-left: 10px;
        &__tooltip {
          opacity: 0;
          z-index: -1;
          position: absolute;
          left: 2.75rem;
          padding: 0rem 1rem;
          background: $color--gray--dark;
          border-radius: $border-radius--medium;
          box-shadow: $shadow--medium;
          @include transition-in(opacity, 200ms);
          &__text {
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            color: $color--background--darkest !important;
            text-overflow: ellipsis;
          }
        }
        &:hover &__tooltip {
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }

  .handle {
    flex: 0;
    padding: 10px;
    cursor: move;
    display: inline-flex;
    align-items: center;
  }

  .actions {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }

  .level {
    @extend %hint-text;
    background-color: $palette--bg--secondary;
    border-radius: 5px;
    padding: 2px 8px;
    margin-right: 0.75rem;
  }

  .loadbar {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
  }
}
