@import '~assets/styles/partials/helpers';

.frame {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.panel {
  flex: 1;
  display: flex;
  flex-direction: row;
  @include layout--medium {
    flex-direction: column;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.banners {
  position: relative;
  flex: 0 0 auto;

  > * {
    margin: 1rem;
  }
}
