.map {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  min-height: 550px;

  :global {
    .mapboxgl-canvas:focus {
      outline: none;
    }
  }
}
