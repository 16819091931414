@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.navigation {
  display: flex;
  position: fixed;
  top: 60px;
  left: -100%;
  background: $color--white;
  box-shadow: $shadow--medium;
  border-bottom-right-radius: 4px;
  z-index: 50;
  max-width: 0;
  max-height: calc(95vh - 60px);
  opacity: 0;
  transform: scale(0.95);
  overflow: scroll;
  overflow-y: scroll;
  @include layout--small {
    max-height: calc(95vh - 120px);
  }
  &--expanded {
    left: unset;
    max-width: 95vw;
    opacity: 1;
    transform: scale(1);
    @include quint-ease-in(opacity, 75ms, transform, 125ms);
  }
  &__block {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-top: 1em;
    padding-bottom: 4em;
    background-color: $color--gray--lightest;
    &__label {
      background: $color--white;
    }
    @include layout--large {
      min-width: 275px;
    }
    @include layout--medium {
      min-width: 22vw;
    }
    @include layout--small {
      min-width: 42vw;
    }
  }
  &__item {
    padding: $spacing;
    margin-bottom: 1em;
    text-transform: capitalize;
    border-right: 4px solid transparent;
    color: $color--primary--dark;
    &:hover {
      background: $color--accent--lightest;
    }
    &--active {
      border-color: $color--accent;
      color: $color--accent;
      background: $color--accent--lightest;
    }
  }
  @include layout--medium {
    max-width: 100vw;
  }
  @include layout--small {
    top: 140px;
  }
}
