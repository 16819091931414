html {
  height: auto;
}

body {
  min-height: 100vh;
  height: auto;
  position: relative;
}

.list-folders {
  display: grid;
  grid-gap: 1em 2em;
  margin-top: 1em;
}

.create-list__select-filters {
  display: grid;
  grid-gap: 1em;
}

.delivery {
  &__select {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5em;
  }
  &__single {
    &__status {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      margin-top: 0.5rem;
      margin-left: auto;
      &__icon {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $palette--accent;
        margin-right: 1em;
      }
    }
    &__details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 8em;
      padding-top: 1em;
      align-items: center;
      .sheet__edit {
        grid-column: -2/-1;
      }
    }
    &__detail-block {
      height: 100%;
    }
    &__filters {
      .chip-block {
        padding-bottom: 0;
      }
      .md-checkbox {
        padding-top: 0;
      }
      .no-county-checkbox {
        padding-top: 0;
      }
    }
  }
  &__registration {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8em;
    padding-top: 1em;
    align-items: center;
    .page-title {
      grid-column: 1/-1;
    }
    .button__group {
      grid-column: 1/-1;
      justify-self: flex-end;
    }
  }
}

.shifts {
  &__upload-scans {
    @extend %row--spread;
    padding-bottom: 2em;
    &__meta {
      flex: 1;
    }
  }
}

.pb-session {
  height: 100vh;
  border-top: $border--small;
  p {
    @extend .reading-text;
  }
  &__summary {
    padding: $spacing;
    background: $palette--bg--secondary;
  }
  &__container {
    margin-top: -2em;
    display: grid;
    grid-template-columns: 3fr 8fr;
    height: 100%;
    @media #{$breakpoint--md} {
      grid-template-columns: 4fr 6fr;
    }
    @media #{$breakpoint--sm} {
      grid-template-columns: 1fr;
      height: auto;
    }
  }
  &__container--no-header {
    display: grid;
    grid-template-columns: 3fr 8fr;
    height: 100%;
    @media #{$breakpoint--md} {
      grid-template-columns: 4fr 6fr;
    }
    @media #{$breakpoint--sm} {
      grid-template-columns: 1fr;
      height: auto;
    }
  }
  &__sidebar {
    background: $palette--bg--secondary;
    border-right: 1px solid $palette--gray--light;
    padding-bottom: 1rem;
    @media #{$breakpoint--sm} {
      overflow: visible;
    }
    label {
      margin-bottom: 0;
    }
    h2 {
      @extend %font__h2;
      color: $palette--accent;
    }
    h3 {
      @extend %font__h3;
      color: $palette--accent;
    }
    h4 {
      @extend %font__h4;
      color: $palette--primary;
    }
    ul:not(.md-list) {
      @extend %font__paragraph;
      list-style: none;
      margin: 2em 0;
      padding-left: 0;
      li {
        margin-bottom: 1.6em;
      }
    }
    &__submitted {
      display: flex;
      align-items: center;
      color: $palette--secondary;
      fill: $palette--secondary;
    }
    &__submitted-text {
      @extend %font__h4;
      margin-right: 0.5em;
      margin-left: 2rem;
    }
  }
  &__main {
    position: relative;
    background: $palette--bg;
    overflow: auto;
    @media #{$breakpoint--sm} {
      max-height: none;
      overflow: visible;
    }
    .update-alert h2 {
      margin-top: 0;
      padding-bottom: 0;
    }
  }
  &__intro {
    margin-top: 2em;
    padding: $spacing;
    h2 {
      margin: 0;
    }
  }
  &__script-container {
    h2 {
      margin: 0 1em;
    }
  }
  &__review-banner {
    width: 100%;
    padding: 2em;
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    background-color: $palette--header-bg;
    border-bottom: $border--large;
  }
  &__end {
    margin-top: 2em;
    padding: $spacing;
  }
}

.packet {
  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20%;
    @media #{$breakpoint--sm} {
      grid-template-columns: 1fr;
    }
  }
}

.exports {
  &__single {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    & > section:first-of-type {
      padding-right: 1em;
      margin-right: 1em;
      border-right: $rule;
    }
    .button__group {
      grid-column-end: -1;
      margin: 2em;
    }
    &__data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      &__columns {
        grid-column: 1/-1;
        ul {
          list-style: none;
          display: grid;
          grid-template-columns: repeat(auto-fit, 250px);
        }
      }
    }
  }
}

.phone-bank {
  &__single {
    &__schedule {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20%;
    }
    &__progress {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr;
      grid-column-gap: 2em;
    }
  }
}

.quality-control__packet-prioritization__modal {
  .dialog--modal__content {
    flex: 1;
  }
}
.quality-control {
  &__inbox {
    &__detail {
      padding: $spacing;
      background: $palette--bg;
      border-top: $rule;
    }
  }
  &__packet-management {
    @extend .margin--top;
    &__main-panel {
      display: grid;
      grid-template-columns: minmax(100px, 1fr) minmax(100px, 3fr);
      &--empty {
        background: $palette--bg;
        padding: $spacing;
        height: 100%;
        border: $rule;
        overflow: hidden;
        height: 250px;
      }
    }
    &__currently-assigned {
      width: 100%;
      border-left: $rule;
      position: relative;
      &__header {
        border: $rule;
        box-shadow: $box-shadow;
      }
      &__actions {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
      .list {
        margin-top: 2px;
        overflow: scroll;
        height: 250px;
        &__item {
          &__content {
            display: flex;
            @media only screen and (min-width: 1308px) {
              max-width: 50%;
            }
            .text-content {
              margin-left: 2em;
            }
          }
        }
      }
    }
  }
}

.pv-scripts {
  &__back-btn {
    display: flex;
    align-items: center;
  }

  &__controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__primary-controls {
      display: flex;
      align-items: center;
    }
  }
}

.weekly-reports {
  &__container {
    display: grid;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    grid-gap: 2em;
    margin: $spacing;
    @media (min-width: $screen-md) {
      grid-template-columns: repeat(2, minmax(400px, 1fr));
    }
  }
  &__section > * {
    margin-bottom: 2em;
  }
  &__section--full-width {
    grid-column: 1/-1;
  }
  .panel__header {
    text-align: center;
    justify-content: center;
  }
  .panel__header__csv {
    text-align: right;
    justify-content: flex-end;
    padding: 0.5rem 2rem;
  }
  .panel__body {
    overflow: hidden;
  }
}

.flag-single {
  &__canvasser-detail {
    width: 30%;
    margin: 1rem 0 1rem 0;
    > h2 {
      margin: $spacing;
    }
    &__item-container {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      &__sub-item {
        margin: $spacing;
        align-self: center;
        > h5 {
          margin-bottom: 0;
          font-weight: bold;
          color: $palette--text--secondary;
        }
      }
    }
  }
}
