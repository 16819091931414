@import '~assets/styles/variables';

.sticky--section {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: sticky;
    width: 100%;
    top: 60px;
    z-index: 2;
    padding: $spacing--medium;
    min-height: 60px;
    background: $color--background;
    border-bottom: $border--small;
    &--active {
      box-shadow: $shadow--medium;
      margin-bottom: -1em;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & > *:not(button):not(span):not(svg):not(i) {
      max-width: 250px;
    }
  }
}

.breadcrumb {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
}
